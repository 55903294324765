@import "../styleConstants.scss";

footer.listing-footer {
	font-family: $font-family-teuton;
	position: sticky;
	bottom: 0;
	margin-top: auto;

	.footer-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		background: var(--tertiary-theme-color);
		padding: 5px;
	}

	.active-filters-message {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 5px;
		background: var(--primary-theme-color);
		color: var(--state-warning-color);

		section.hint {
			padding: 5px;

			button {
				margin: 5px;
				background: var(--secondary-theme-color);
				color: var(--primary-theme-color);
				padding: 5px 15px;
				font-weight: bold;

				&:hover {
					background: var(--tertiary-theme-color, white);
				}
			}
		}
	}

	.pagination {
		grid-area: pagination;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 1;

		button {
			border: 3px solid transparent;
			font-weight: bold;
			height: 2em;
			min-width: 2em;

			&[active="true"] {
				border-color: var(--primary-theme-color);
				background: var(--secondary-theme-color) !important;
			}
		}
	}

	.size-picker {
		grid-area: size;
		background: var(--primary-theme-color);
		border: none;
		color: var(--secondary-theme-color);

		input {
			font-weight: bold;
		}
	}
}
