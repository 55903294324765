section.opening-hours {
	display: flex;
	flex-direction: column;

	.entries {
		flex: 1;
		overflow-y: auto;
		margin: 20px 0;
		max-height: 50vh;

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
			cursor: not-allowed;
			filter: brightness(0.8);
		}

		.entry {
			display: flex;
			margin: 5px;

			.entry-header {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin-right: 20px;
				align-self: flex-start;
				margin-top: 5px;

				label {
					min-width: 100px;
					text-align: right;
					margin-right: 20px;
				}
			}
		}

		.periods {
			display: flex;
			flex-direction: column;
			margin: 0 auto;

			.period {
				display: flex;
				align-items: center;
				margin: 5px 0;
			}

			.add-period {
				align-self: flex-start;
			}
		}
	}
}

@media screen and (max-width: 730px) {
	.entries {
		max-height: 63vh !important;

		.entry {
			flex-wrap: wrap;
		}
	}
}
