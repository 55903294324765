@import "../../../styleConstants.scss";

section.service-actions {
	margin: 30px 0;

	.action-wrapper {
		.primary-tag {
			display: flex;
			justify-content: flex-end;
			visibility: hidden;
		}

		&.primary {
			.primary-tag {
				visibility: visible;
			}
		}
	}

	.action {
		background: var(--secondary-theme-color);
		color: var(--primary-theme-color);
		margin-bottom: 10px;
		position: relative;
		text-align: initial;
		display: flex;

		&.primary {
			outline: 3px solid #ccc;
		}

		&.disabled {
			background: var(--primary-theme-color);
			color: #ccc;

			.action-details {
				opacity: 0.5;
				filter: brightness(0.8);

				&::after {
					@include mixin-absolute-content;
					background: repeating-linear-gradient(
						-45deg,
						transparent,
						transparent 10px,
						var(--tertiary-theme-color) 10px,
						var(--tertiary-theme-color) 20px
					);
					opacity: 0.1;
				}

				&:hover {
					&::after {
						opacity: 0.2;
					}
				}
			}
		}

		.action-details {
			padding: 10px;
			flex: 1;
			position: relative;
		}

		&.edit {
			.action-details {
				&:hover {
					background: var(--tertiary-theme-color);
				}
			}
		}

		.server-image {
			position: absolute;
			top: 0;
			left: 0;
			transform: translate(-50%, -50%);
			background: var(--secondary-theme-color);
			border: 3px solid var(--primary-theme-color);
			width: 3em;
			height: 3em;
			z-index: 1;
			--cube-size: 2em;
		}

		.action-buttons {
			display: flex;
			flex-direction: column;
			border-left: 3px solid var(--primary-theme-color);

			button {
				flex: 1;
				width: 100%;

				&:not([disabled]):hover {
					background: var(--tertiary-theme-color);
				}
			}
		}

		&.primary {
			.action-buttons {
				border-color: var(--tertiary-theme-color);
			}
		}
	}

	.add-action {
		background: var(--secondary-theme-color);
		color: var(--primary-theme-color);

		&:hover {
			background: var(--tertiary-theme-color);
		}
	}
}

@media screen and (max-width: 1000px) {
	section.service-actions {
		.action {
			.action-details {
				@include grid-icon-auto-1fr-wrap;
			}
		}
	}
}

@media screen and (max-width: 410px) {
	section.service-actions {
		.action {
			flex-direction: column;

			.action-buttons {
				border-top: 3px solid var(--primary-theme-color);
				border-left: none;
				flex-direction: row;

				button {
					padding: 1em;
				}
			}
		}
	}
}
