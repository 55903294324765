@import "../../styleConstants";

section.feedback-detail {

	.feedback {
		padding: 20px 40px;
	}

	.dropzone {
		margin-top: 20px;
	}
}
