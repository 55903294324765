section.service-camera-modal {
	.image-container {
		width: 100%;
		min-height: 200px;
		max-width: 100%;
		max-height: 100%;
		margin-bottom: 20px;
		border: 5px solid var(--primary-theme-color);
		display: flex;
		position: relative;
		background: var(--primary-theme-color);

		.server-image {
			width: 100%;
		}
	}

	.overlay {
		position: absolute;
		display: flex;
		background: transparent;

		--cube-color: var(--secondary-theme-color);
	}
}
