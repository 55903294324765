@import "../../../styleConstants";
@import "StorageUnitScene.scss";
@import "CubeCheckbox.scss";
@import "StorageUnitRow.scss";
@import "StorageFrame.scss";
@import "StorageBlock.scss";
@import "StorageService.scss";
@import "TypePickers.scss";
@import "StorageEdit.scss";

section.storage-unit {
	.view-switch {
		overflow: hidden;
	}

	header.storage-unit-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		width: 90%;
		margin: 0 auto;

		nav {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			.fancy {
				display: flex;
				align-items: center;
				justify-content: center;

				&:not(:last-of-type) {
					border-right: 0;
				}
			}

			button.spacer {
				padding: 0 20px;
				width: 0;
			}
		}
	}

	.unit-control {
		align-self: flex-end;
		margin: 0 20px -15px 0;
		border: 5px solid var(--primary-theme-color);
		border-radius: 10px 10px 0 0;
		padding: 5px 10px;

		svg {
			margin-right: 10px;
		}

		&:hover {
			background: var(--tertiary-theme-color, white);
		}
	}

	.face-content {
		flex: 1;
		width: 100%;
		height: 100%;
		display: flex;
		position: relative;

		&::before,
		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			bottom: 17px;
			width: 10px;
			color: #fff;
			background: repeating-linear-gradient(
				-45deg,
				var(--primary-theme-color),
				var(--primary-theme-color) 10px,
				var(--secondary-theme-color) 10px,
				var(--secondary-theme-color) 20px
			);
		}

		&::before {
			background: repeating-linear-gradient(
				45deg,
				var(--primary-theme-color),
				var(--primary-theme-color) 10px,
				var(--secondary-theme-color) 10px,
				var(--secondary-theme-color) 20px
			);
			right: unset;
			left: 0;
			z-index: 1;
		}
	}

	@include scene;
	@include storage-unit-row;
	@include storage-frame;
	@include storage-block;
	@include storage-service;
	@include storage-edit;
}
