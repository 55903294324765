@import "../styleConstants";

span.progress-bar {
	display: inline-block;
	min-height: 1em;
	min-width: 10em;
	vertical-align: -0.2em;
	border: 3px solid var(--primary-theme-color);
	position: relative;
	color: var(--tertiary-theme-color, white);

	&::before {
		@include mixin-absolute-content;
		right: calc(100% - 1% * var(--progress-bar-value));
		background: currentColor;
		transition: 0.2s all ease-in-out;
	}

	&.vertical {
		&::before {
			right: 0;
			bottom: calc(100% - 1% * var(--progress-bar-value));
		}
	}
}
