section.table-listing {
	overflow: auto;
	position: relative;
	flex: 1;
	min-height: 200px;
	display: flex;
	flex-direction: column;

	header {
		background: var(--primary-theme-color);
		position: sticky;
		top: 0;
		z-index: 2;

		button {
			display: flex;
			justify-content: flex-start;
			text-align: left;
			cursor: default;

			label {
				color: var(--secondary-theme-color);
				text-align: left;

				.sort-icon {
					margin-left: 10px;
					display: none;

					&.active-sort {
						display: inline-block;
					}
				}

			}

			&.sortable {
				cursor: pointer;

				&:hover {
					label {
						color: var(--tertiary-theme-color);

						/*.sort-icon { //Causes confusing hover on sortable headers. Intended function was to show next sort option (asc/desc/off).
							display: inline-block;
						}*/
					}
				}
			}

			&.sorted {
				label {
					color: var(--tertiary-theme-color);

					.sort-icon {
						display: inline-block;
					}
				}
			}

			&.columns-setup {
				opacity: 0.5;
				color: var(--tertiary-theme-color, white);
				cursor: pointer;
			}
		}
	}

	& > * {
		display: flex;
		width: 100%;
		padding: 10px;
		text-decoration: none;
		text-align: left;
		align-items: center;

		a {
			&:hover {
				color: var(--state-info-color);
			}
		}

		& > * {
			flex: 4;
			padding: 1px 6px;
		}
	}

	& > a,
	& > button,
	& > .button {
		&:hover {
			background: var(--tertiary-theme-color, white) !important;
		}
	}

	&.stripes {
		& > a:nth-of-type(2n),
		& > button:nth-of-type(2n) {
			background: rgba(0, 0, 0, 0.1);
		}
	}

	&:not(.no-fade-in) {
		& > * {
			opacity: 0;
			animation: fadeIn 0.5s forwards;
		}
	}

	&.modify-columns-offset {
		& > *:not(header) {
			& > *:last-child {
				margin-right: 1.5em;
			}
		}
	}

	.table-item-row {
		position: relative;

		a.row-redirect {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.item-redirect {
			position: relative;

			.fa {
				margin-left: auto;
			}
		}
	}

	.elevate {
		position: relative;
		z-index: 1;
	}

	@for $i from 0 through 100 {
	    & > *:nth-of-type(#{$i + 1}n) {
	        animation-delay: #{$i * 0.01}s !important;
	    }
	}
}

@media screen and (max-width: 800px) {
	section.table-listing {
		& > * {
			min-width: 800px;
		}
	}
}
