@import "../../../styles/styleConstants.scss";

section.user-detail {
	.user {
		padding: 20px 40px;
	}

	.content {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.user-permissions {
		.grid-table {
			max-width: 600px;
			margin: auto;
		}

		nav {
			display: flex;
			justify-content: flex-end;
			border: 3px solid var(--primary-theme-color);
			margin: 5px;

			button {
				flex: 1;
				padding: 5px 10px;
				outline: 3px solid var(--primary-theme-color);

				&:not(:last-of-type) {
					margin-right: 3px;
				}

				&:hover {
					background: var(--tertiary-theme-color, white);
				}
			}
		}
	}

	.credit-table {
		margin: auto;
		grid-template-columns: auto 1fr auto;

		.remove-button {
			span {
				margin-left: 5px;
				display: none;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	section.user-detail {
		.user-permissions {
			.grid-table {
				@include grid-icon-auto-1fr-wrap;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	section.user-detail[edit="true"] {
		.content {
			.credit-table {
				display: flex;
				flex-wrap: wrap;

				.dropdown {
					width: 100%;
					margin-bottom: 5px;

					&:not(:first-of-type) {
						margin-top: 20px;
					}
				}

				.input-wrapper {
					flex: 1;
				}

				.remove-button {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 5px;
					flex: 1;

					span {
						display: initial;
					}
				}
			}
		}
	}
}
