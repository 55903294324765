section.language-switch {
	position: relative;

	.header {
		display: flex;

		.overflow-menu {
			position: relative;
			align-self: flex-end;
			justify-content: flex-end;
			display: flex;
			flex: 1;
			margin: 0 20px -3px 0;
			z-index: 1;

			button,
			a,
			.button {
				border: 3px solid var(--primary-theme-color);
				border-radius: 10px 10px 0 0;
				padding: 5px 10px 1px 10px;
				user-select: none;
				outline: none;

				&:focus,
				&:hover {
					background: var(--tertiary-theme-color, white);
				}

				&[active="true"] {
					border-bottom-color: var(--tertiary-theme-color, white);
				}

				a {
					pointer-events: all;
					border: none;
					margin: 0 -10px 0 0;
					cursor: pointer;

					&:hover {
						color: var(--state-error-color);
					}
				}
			}

			nav {
				flex: 1;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;

				button,
				.button {
					margin: 0 10px 0 0;
				}
			}
		}
	}

	.language-container {
		border: 3px solid var(--primary-theme-color);
		margin-bottom: 20px;
		position: relative;
		min-height: 150px;
		display: flex;
		justify-content: center;
		align-items: center;

		& > * {
			width: 100%;
			height: 100%;
			border: none;
		}

		& > textarea {
			min-height: 150px;

			&::placeholder {
				color: var(--primary-theme-color);
				opacity: 0.7;
				font-weight: normal;
			}
		}

		.no-value {
			text-align: center;
			opacity: 0.5;
			font-size: 2em;
		}
	}
}
