@import "../../../styleConstants";

section.storage-service-detail {
	color: #ccc;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: auto;

	.data-grid {
		display: grid;
		grid-template-columns: 2em 1fr 1fr;
		grid-template-rows: auto;
	}

	header {
		margin: -80px 100px 0 0;
		padding-left: 0 !important;
		justify-content: unset !important;

		h1 {
			margin-right: 20px;
			font-size: 1.8em;
		}
	}

	.service-buttons {
		position: sticky;
		top: 0;

		display: grid;
		grid-template-columns: repeat(auto-fit, 150px);
		grid-gap: 10px;

		min-width: 200px;
		justify-content: flex-end;
		color: var(--primary-theme-color);
		flex: 7;

		.button,
		a,
		button {
			padding: 10px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: var(--secondary-theme-color);
			width: 100%;
			height: 100%;
			font-family: $font-family-teuton;
			font-weight: bold;
			text-align: center;
			margin-bottom: 5px;

			&:not([disabled]):hover {
				background: var(--tertiary-theme-color, white);
			}

			.fa {
				font-size: 1.5em;
				margin: 0.2em 0;
			}
		}
	}

	h4 {
		font-family: $font-family-teuton;
		font-weight: bold;
	}

	.service-settings {
		margin: 20px 10px;
		flex: 10;
	}
}

@media screen and (max-width: 1100px) {
	section.storage-service-detail {
		.service-buttons {
			position: static;
			flex: 0;
			min-width: unset;
			margin-left: 20px;
		}
	}
}

@media screen and (max-width: 700px) {
	section.storage-service-detail {
		.service-data {
			height: 100%;
			display: block;
			flex: unset;
		}

		.service-buttons {
			min-width: unset;
			grid-template-columns: 1fr;
			position: static;
			padding-bottom: 30px;
			margin-left: 0;

			&.grid-table {
				margin-top: auto;
				width: 100%;

				& > * {
					width: 100%
				}
			}
		}
	}
}

@media screen and (max-width: 650px) {
	section.storage-service-detail {
		.grid-table {
			@include grid-icon-auto-1fr-wrap;
		}
	}

	aside.expand-container {
		.expand-header {
			flex-direction: column-reverse;
			margin-left: 0;

			nav {
				align-self: flex-end;
				margin-bottom: 1em;
			}

			.input-wrapper {
				width: 100%;
			}
		}
	}
}
