@mixin storage-block {
	.storage-block {
		flex: 1;
		width: 100%;
		height: 100%;

		&[data-block-type="ADD"] {
			display: flex;

			button {
				flex: 1;
				background: var(--frame-color);
				color: var(--frame-background-color);

				&:hover {
					background: var(--hover-background-color);
				}
			}
		}

		&[data-block-type="SINGLE"] {
			font-size: 1.4em;
		}

		&[data-block-type="DOUBLE_VERTICAL"] {
			display: grid;
			grid-template-rows: 1fr 1fr;
			grid-template-columns: 1fr;
			grid-gap: var(--gap-size);
		}

		&[data-block-type="TRIPLE_VERTICAL"] {
			display: grid;
			grid-template-rows: 1fr 1fr 1fr;
			grid-template-columns: 1fr;
			grid-gap: var(--gap-size);
		}

		&[data-block-type="QUADRUPLE_VERTICAL"] {
			display: grid;
			grid-template-rows: 1fr 1fr 1fr 1fr;
			grid-template-columns: 1fr;
			grid-gap: var(--gap-size);
			font-size: 0.7em;

			.state-icons {
				font-size: 0.6em;
			}
		}

		&[data-block-type="QUINTUPLE_VERTICAL"] {
			display: grid;
			grid-template-rows: repeat(5, 1fr);
			grid-template-columns: 1fr;
			grid-gap: var(--gap-size);
			font-size: 0.6em;

			.state-icons {
				font-size: 0.6em;
			}

			.storage-service div {
				padding: 5px;
			}
		}

		&[data-block-type="CUSTOM_HEIGHT"] {
			display: grid;
			grid-template-rows: repeat(6, 1fr);//FFS: Should be dynamic
			grid-template-columns: 1fr;
			grid-gap: var(--gap-size);
			font-size: 0.5em;

			.state-icons {
				font-size: 0.6em;
			}

			.storage-service div {
				padding: 5px;
			}
		}
	}
}
