.cs-modal-container {
	--modal-background: var(--secondary-theme-color);
	--modal-color: var(--primary-theme-color);

	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	pointer-events: none;
	display: none;
	z-index: 200;

	&.fadeIn {
		display: flex;
	}

	.overlay {
		display: block;
		pointer-events: initial;
	}

	.cs-modal {
		min-width: 400px;
		max-width: 100%;

		min-height: 100px;
		max-height: 100%;

		margin: auto;
		background: var(--modal-background, var(--secondary-theme-color));
		color: var(--modal-color, var(--primary-theme-color));
		border: 5px solid var(--primary-theme-color);
		pointer-events: initial;
		z-index: 1;
		display: flex;
		flex-direction: column;
		position: relative;

		&.dark {
			color: #ccc;
			--modal-background: var(--primary-theme-color);
		}

		&.wide-content {
			width: 700px;
		}

		&.full-height-content {
			height: 100%;
		}

		&.full-content {
			width: 100%;
			height: 100%;
		}

		& > header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px 5px 5px 1em;
			margin-bottom: 15px;

			& > label {
				font-size: 1.5em;
			}

			nav {
				display: flex;
				justify-content: flex-end;
				flex: 1;

				button {
					margin: 0 0.2em;
					font-size: 1.5em;
					position: relative;
					z-index: 2;
					color: inherit;

					&:hover {
						opacity: 0.5;
					}
				}
			}
		}

		& > div {
			overflow: auto;
			flex: 1;
			padding: 0 3em 2em;
			display: flex;

			& > * {
				flex: 1;
				display: flex;
				flex-direction: column;
			}
		}
	}
}

@media screen and (max-width: 730px) {
	.cs-modal-container {
		.cs-modal {
			& > div {
				padding: 0 1em 2em;
			}
		}
	}
}

@media screen and (max-width: 400px) {
	.cs-modal-container {
		.cs-modal {
			min-width: unset;
			width: 100%;
		}
	}
}
