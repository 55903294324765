@import "../../../styleConstants";

@mixin storage-edit {
	.box {
		--outline-color: var(--tertiary-theme-color);

		&[data-edit-mode="NONE"] {
			.storage-frame {
				&[data-frame-type="NONE"] {
					pointer-events: none;
				}
			}

			.storage-block {
				&[data-block-type="ADD"] {
					pointer-events: none;
				}
			}
		}

		&:not([data-select-mode="NONE"]) {
			.storage-frame {
				pointer-events: none;
			}
		}

		&[data-edit-mode="DELETE"] {
			--outline-color: var(--state-error-color);
		}

		&[data-select-mode="FRAME"] {
			.storage-frame {
				pointer-events: all;

				&:not([data-frame-type="ADD"]):not([data-frame-type="NONE"]) {
					background: var(--frame-color);

					&:hover {
						&::after {
							@include mixin-absolute-content;
							outline: 5px solid var(--outline-color);
							z-index: 1;
						}
					}

					& > * {
						opacity: 0.5;
					}
				}

				& > * {
					pointer-events: none;
				}
			}
		}

		&:not([data-select-mode="FRAME"]):not([data-select-mode="NONE"]) {
			.storage-frame:not([data-frame-type="NONE"]):not([data-frame-type="ADD"]) {
				&[data-simple="true"] {
					opacity: 0.5;
				}
			}
		}

		&:not([data-select-mode="FRAME"]) {
			.storage-frame {
				& > .state-icons {
					display: none;
				}
			}
		}

		&[data-select-mode="BLOCK"] {
			.storage-block {
				pointer-events: all;

				&:not([data-block-type="ADD"]) {
					background: var(--frame-color);

					&:hover {
						outline: 5px solid var(--outline-color);
					}

					& > * {
						opacity: 0.5;
						pointer-events: none;
					}
				}
			}
		}

		&[data-select-mode="SERVICE"] {
			.storage-service {
				pointer-events: all;

				&:hover {
					text-decoration: none;
					background: var(--hover-background-color);
					opacity: 1;
					filter: none;

					div {
						background: transparent;
					}
				}
			}
		}

		&[data-edit-mode="EDIT"] {
			.storage-frame {
				&[data-frame-type="NONE"],
				&[data-frame-type="ADD"] {
					pointer-events: all;
				}
			}

			&:not([data-select-mode="FRAME"]) {
				.storage-block {
					&[data-block-type="ADD"] {
						pointer-events: all;
					}
				}
			}
		}

		&[data-edit-mode="DELETE"] {
			.storage-frame {
				&[data-frame-type="ADD"] {
					pointer-events: none;
				}

			}

			&[data-select-mode="FRAME"] {
				.storage-frame {
					&[data-frame-type="NONE"] {
						background: var(--frame-color);
						color: var(--frame-background-color);

						&:hover {
							outline: 5px solid var(--outline-color);
							border-color: var(--outline-color);
						}
					}
				}
			}

			&:not([data-select-mode="FRAME"]) {
				.storage-frame {
					&[data-simple="true"] {
						opacity: 0.5;
					}
				}
			}

			&[data-select-mode="BLOCK"] {
				.storage-block {
					&[data-block-type="ADD"] {
						pointer-events: none;
						opacity: 0.5;
					}
				}
			}

			&[data-select-mode="SERVICE"] {
				.storage-block {
					&[data-block-type="ADD"] {
						opacity: 0.5;
					}
				}

				.storage-service {
					&[empty="true"] {
						pointer-events: none;
					}

					&:not([empty="true"]) {
						&:hover {
							background: var(--frame-color);
							color: var(--frame-background-color);
							outline: 5px solid var(--state-error-color);
						}
					}
				}
			}
		}
	}
}
