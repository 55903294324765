@keyframes toast-fade-in {
	from {
		transform: translateX(-50%);
		opacity: 0.2;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes toast-fade-out {
	from {
		opacity: 1;
		transform: translateY(0);
	}
	to {
		opacity: 0;
		transform: translateY(-50%);
	}
}

#toaster {
	position: fixed;
	left: 50px;
	bottom: 50px;
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-end;
	z-index: 9999;

	& > div {
		transition: 0.2s all;

		&.fade-out {
			animation: 0.3s toast-fade-out forwards;
		}
	}

	.toast {
		--progress-height: 5px;
		--toaster-text-color: var(--secondary-theme-color);

		min-width: 250px;
		min-height: 60px;
		background: var(--primary-theme-color);
		border: 1px solid var(--secondary-theme-color);
		animation: 0.2s toast-fade-in forwards;
		margin: 5px 0;
		border-radius: 0;
		position: relative;
		color: var(--secondary-theme-color);
		display: flex;
		flex-direction: column;
		cursor: pointer;

		&[toast-style="INFO"] {
			background: var(--state-info-color);
			--toaster-text-color: var(--tertiary-theme-color, white);
		}

		&[toast-style="SUCCESS"] {
			background: var(--state-success-color);
			--toaster-text-color: var(--tertiary-theme-color, white);
		}

		&[toast-style="WARNING"] {
			background: var(--state-warning-color);
			--toaster-text-color: var(--primary-theme-color);
		}

		&[toast-style="ERROR"] {
			background: var(--state-error-color);
			--toaster-text-color: var(--tertiary-theme-color, white);
		}

		div.toast-container {
			flex: 1;
			height: calc(100% - var(--progress-height));
			width: 100%;
			display: flex;
			align-items: center;
			font-size: 1.2em;
			padding: 5px 10px;
			color: var(--toaster-text-color);
			pointer-events: none;

			.fa {
				font-size: 1.6em;
				margin: 0 0.5em;
			}

			& > div {
				white-space: pre-line;
			}
		}


		progress {
			width: 100%;
			height: 5px;
			appearance: none;

			&::-webkit-progress-bar {
				background: rgba(0,0,0,0.1);
			}

			&::-webkit-progress-value {
				background: var(--toaster-text-color);
				opacity: 0.5;
			}
		}

		::-moz-progress-bar {
			background: var(--toaster-text-color);
			opacity: 0.5;
		}
	}
}
