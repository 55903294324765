.box {
	.frame {
		position: relative;

		&:first-of-type {
			.remove-frame {
				border-left: none;
			}
		}

		.remove-frame {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 0;
			overflow: hidden;
			width: 100%;
			background: var(--secondary-theme-color);
			color: var(--primary-theme-color);
			transition: 0.2s all;
			font-size: 2rem;
			border-left: 5px solid var(--primary-theme-color);
			opacity: 0.8;

			&:hover {
				background: var(--primary-theme-color);
				color: var(--secondary-theme-color);
				opacity: 1;
			}
		}

		&[edit="true"]:hover {
			.remove-frame {
				height: 2em;
				border-top: 5px solid var(--primary-theme-color);
			}
		}
	}
}