@import "../styleConstants.scss";

section.summary-table {
	border: 5px solid var(--primary-theme-color);

	& > div {
		& > label {
			width: 100%;
			background: var(--primary-theme-color);
			padding: 5px 10px;
		}

		& > label:not(.cl-success):not(.cl-error) {
			color: var(--secondary-theme-color);
		}
	}

	.data-table {
		grid-template-columns: auto auto;
		grid-gap: 0;

		label {
			padding: 2px 0;
		}

		.with-suffix {
			margin: 0;
			padding: 2px 10px;
		}

		&.stripes {
			& > *:nth-child(4n) {
				position: relative;
				background: rgba(0, 0, 0, 0.2);
			}

			& > *:nth-child(4n - 1) {
				background: rgba(0, 0, 0, 0.2);
				position: relative;
				width: 100%;
				height: 100%;
			}
		}
	}

	.theme-white {
		background: var(--tertiary-theme-color, white);
	}

	.theme-dark {
		background: var(--primary-theme-color);
		color: var(--secondary-theme-color);
	}

	.payment-summary {
		label {
			text-align: right;
			font-weight: bolder;
			font-size: 1.5em;
		}
	}
}
