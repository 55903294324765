nav.frame-select {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 10px;
	color: var(--secondary-theme-color);
	flex: 1;
	overflow: auto;
	padding: 10px;

	button.fancy {
		color: inherit;
		width: unset;
		border-color: var(--secondary-theme-color);
		flex: 1;
		border-width: 5px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&:hover {
			color: var(--primary-theme-color);
			border-color: transparent;
		}

		.fa {
			font-size: 10vw;
			margin: 0;
		}
	}
}

@media screen and (min-width: 1500px) {
	nav.frame-select {
		font-size: 2em;
	}
}
