@import "../../styleConstants";

section.dropzone-image-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 25px 20px 20px;

	.image-preview {
		width: calc(100% / 5);
		min-width: 150px;
		max-width: 150px;
		height: 150px;
		overflow: hidden;
		background: var(--secondary-theme-color);
		border: 3px solid var(--primary-theme-color);
		margin: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 1;
		position: relative;

		& > * {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			min-width: unset;
			border: none;

			&.progress-bar {
				opacity: 0.7;
			}
		}

		.spinner {
			position: static;
			width: 40%;
			height: 40%;
		}

		.fa {
			font-size: 2em;
		}

		.image-wrapper {
			width: 100%;
			height: 100%;
		}

		nav {
			@include mixin-absolute-content;
			display: flex;
			justify-content: center;
			align-items: center;

			.fa {
				display: none;
			}
		}

		&.failed {
			border-color: var(--state-error-color);

			.error-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;

				.error-icon {
					color: var(--state-error-color);
				}
			}

			.image-wrapper {
				filter: grayscale(1);
			}
		}

		&:hover {
			nav {
				background: var(--primary-theme-color);
				color: var(--secondary-theme-color);

				button {
					color: inherit;
				}

				.fa {
					font-size: 4em;
					display: block;
				}
			}
		}
	}
}
