@import "../../../styles/styleConstants.scss";

section.authorization-detail {
	.authorization {
		padding: 20px 40px;
	}

	.content {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.restrictions {

	}
}
