section.location-list {
	display: flex;
	flex-direction: column;

	a * {
		cursor: pointer;
	}

	.input-wrapper {
		border-width: 5px;
	}

	.location-list-wrapper {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden auto;
	}

	.list-wrapper {
		flex: 1;
		overflow-y: auto;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

		.list-item {
			height: 150px;
			display: flex;
			justify-content: stretch;
			align-items: stretch;

			.front {
				.available {
					position: absolute;
					top: 10px;
					left: 10px;
					color: var(--state-success-color);
				}

				.state-icons {
					position: absolute;
					bottom: 0;
					right: 0;
					padding: 0.5em;

					.fa {
						color: var(--tertiary-theme-color, white);
						filter: brightness(0.5);
						margin-left: 10px;
					}

					.out-of-order {
						color: var(--state-error-color);
						filter: unset;
					}

					.off-hours {
						font-size: 1.2em;
					}
				}

				label {
					font-size: 2em;
				}
			}

			.back {
				label {
					margin-left: -10px;
					font-size: 1.2em;
				}
			}
		}
	}
}
