section.reservation-calendar-wrapper {
	background: var(--primary-theme-color);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 10px;
	min-height: 500px;
	height: 100%;

	header {
		color: var(--secondary-theme-color);
		display: flex;
		justify-content: center;
		padding: 10px;

		nav {
			label {
				text-align: center;
				width: 300px;
			}
		}
	}

	.set-today {
		color: var(--secondary-theme-color);

		&:not([disabled]):hover {
			color: var(--tertiary-theme-color);
		}
	}

	.change-orientation {
		color: var(--secondary-theme-color);
		font-size: 2em;
		border-radius: 50%;

		.fa {
			transition: 0.2s transform ease-in-out;
			transform: rotate(-45deg);
		}

		&:not([disabled]):hover {
			color: var(--tertiary-theme-color);
		}
	}

	.clear-selection {
		background: var(--secondary-theme-color);
		padding: 5px;
		margin-right: 10px;
		border: 3px solid var(--primary-theme-color);

		&:not([disabled]):hover {
			background: var(--tertiary-theme-color);
		}
	}

	.reservation-calendar {
		padding: 5px;
		color: var(--secondary-theme-color);
		overflow: auto;

		.time {
			font-size: 0.8em;
		}

		.day {
			margin-bottom: 5px;
			position: relative;
			display: flex;

			& > label {
				min-width: 5em;
				text-align: right;
				padding: 5px 10px;
				text-transform: capitalize;

				&.today {
					color: var(--tertiary-theme-color);
				}
			}

			.hours {
				display: flex;
			}
		}

		.entry {
			background: var(--secondary-theme-color);
			color: var(--primary-theme-color);
			min-width: 4em;
			margin-right: 5px;

			label {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.fa {
				display: none;
			}

			&[disabled] {
				opacity: 0.25;
			}

			&:not([disabled]):not(.inner):hover {
				background: var(--tertiary-theme-color);
				opacity: 0.9;

				span {
					display: none;
				}

				.fa {
					display: flex;
				}
			}

			&.now {
				outline: 3px solid var(--tertiary-theme-color);
			}

			&.RESERVED,
			&.UNAVAILABLE {
				background: var(--primary-theme-color);
				cursor: not-allowed;
				color: var(--tertiary-theme-color);
			}

			&.selected {
				background: var(--tertiary-theme-color);

				&.inner {
					cursor: not-allowed;
				}
			}
		}
	}

	footer {
		margin-top: auto;
	}

	&.orientation-vertical {
		.change-orientation {
			color: var(--secondary-theme-color);
			font-size: 2em;
			border-radius: 50%;

			.fa {
				transform: rotate(45deg);
			}
		}

		.reservation-calendar {
			display: flex;

			.day {
				flex-direction: column;
				flex: 1;

				&:not(:last-child) {
					margin-right: 5px;
				}

				label {
					text-align: center;
				}

				.hours {
					flex-direction: column;

					.entry {
						width: 100%;
						height: 2em;
						margin-right: 0;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	section.reservation-calendar-wrapper {
		header {
			nav {
				width: 100%;
				display: flex;
				justify-content: space-between;

				label {
					width: unset;
				}
			}
		}
	}
}
