@mixin scene {
	.scene {
		--box-width: 90%;
		--box-height: 500px;
		position: relative;

		margin: 0 auto;
		width: var(--box-width);
		height: var(--box-height);
		perspective: calc(var(--box-width) + 2550px);

		hr {
			position: absolute;
			top: 50%;
			width: 100%;
			transform: translateY(-50%) scaleX(2000);
			background: var(--primary-theme-color);
			height: 10px;
			border: none;
		}

		.box {
			width: 100%;
			height: 100%;
			transform-style: preserve-3d;
			transform: translateZ(-50px);
			transition: transform 1s;

			&.show-front {
				transform: translateZ(-50px) rotateY(0deg);
			}

			&.show-back {
				transform: translateZ(-50px) rotateY(-180deg);
			}

			&.show-right {
				transform: translateZ(-150px) rotateY(-90deg);
			}

			&.show-left {
				transform: translateZ(-150px) rotateY(90deg);
			}

			&.show-top {
				transform: translateZ(-100px) rotateX(-90deg);
			}

			&.show-bottom {
				transform: translateZ(-100px) rotateX(90deg);
			}
		}

		.box__face {
			position: absolute;
			font-size: 40px;
			font-weight: bold;
			color: var(--tertiary-theme-color, white);
			background: var(--primary-theme-color);
			text-align: center;
		}

		.box__face--front,
		.box__face--back {
			width: 100%;
			height: 100%;
		}

		.box__face--right,
		.box__face--left {
			width: 100px;
			height: 100%;
			left: calc(100% - 200px);
		}

		.box__face--left {
			left: 100px;
		}

		.box__face--top,
		.box__face--bottom {
			width: 100%;
			height: 100px;
			top: 50px;
		}

		.box__face--bottom {
			top: calc(100% - 150px);
		}

		.box__face--front {
			transform: rotateY(0deg) translateZ(50px);
		}

		.box__face--back {
			transform: rotateY(180deg) translateZ(50px);
		}

		.box__face--right {
			transform: rotateY(90deg) translateZ(150px);
		}

		.box__face--left {
			transform: rotateY(-90deg) translateZ(150px);
		}

		.box__face--top {
			transform: rotateX(90deg) translateZ(100px);
		}

		.box__face--bottom {
			transform: rotateX(-90deg) translateZ(100px);
		}
	}
}
