section.error-block {
	&,
	& > div {
		display: flex;
		justify-content: center;
		align-items: center;

		& > .fa {
			margin-bottom: 10px;
		}
	}

	&.overlap-container {
		position: absolute; top: 0;left: 0;bottom: 0;right:0;
	}

	&.border {
		border: 5px solid var(--primary-theme-color);
	}

	&.dark {
		background: var(--primary-theme-color);
		color: var(--state-error-color);
	}

	& > div {
		flex-direction: column;
	}
}
