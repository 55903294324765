section.view-switch {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: auto;

	header.view-header {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		padding: 10px;

		.input-wrapper {
			border-width: 3px;
		}

		.action-buttons {
			display: flex;

			button {
				display: flex;
				padding: 7px;
				justify-content: center;
				margin-left: 10px;
				border: 3px solid var(--primary-theme-color);
				height: 100%;
				font-size: 1.2em;

				&:hover {
					background: var(--tertiary-theme-color, white);
				}
			}
		}
	}
}
