div.server-image {
	position: relative;

	.progress-bar {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: none;
		opacity: 0.5;
	}

	& > button {
		flex: 1;
		width: 100%;
		height: 100%;
		padding: 0;

		.error-block {
			flex: 1;

			.fa {
				margin: 0;
			}
		}
	}

	img {
		max-width: 100%;
		max-height: 100%;
	}
}
