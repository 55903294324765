section.language-list {
	display: flex;
	flex-direction: column;

	button {
		padding: 5px 0;
		color: var(--tertiary-theme-color, white);
		font-size: 1.2em;
		display: flex;

		&:hover {
			color: var(--secondary-theme-color);
		}

		&[active="true"] {
			font-weight: bold;
			color: var(--secondary-theme-color);
			background: none !important;
		}

		div {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-around;
			align-items: center;
		}
	}
}
