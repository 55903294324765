section.dropzone-file-container {
	.file-preview {
		.fa {
			margin-right: 10px;
		}

		.progress-bar {
			margin: 0 10px;
			color: var(--secondary-theme-color);
			border-width: 2px;
		}
	}
}
