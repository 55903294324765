section.communication-units {
	.grid-table label {
		margin-top: -25px;

		&:first-of-type {
			margin-left: -25px;
			padding-left: 25px;
		}

		&:last-of-type {
			margin-right: -25px;
			padding-right: 25px;
		}
	}
}
