@import "../styleConstants.scss";

aside.expand-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 100%;
	bottom: 100%;
	transition: 0.3s all;
	background: var(--primary-theme-color);
	padding: 5px;
	overflow: hidden;
	color: var(--secondary-theme-color);
	display: flex;
	flex-direction: column;

	&.expand-COLLAPSED {
		visibility: hidden;
		pointer-events: none;
		width: 0;
		height: 0;
	}

	&.expand-NORMAL,
	&.expand-FULL {
		display: flex;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	&.expand-FULL {
		position: fixed;
		z-index: 99;
	}

	& > .expand-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		margin-left: 30px;

		label {
			display: block;
		}

		nav {
			align-self: flex-start;
			justify-self: flex-end;
			z-index: 1;
			display: flex;

			a,
			.button,
			button {
				color: currentColor;
				margin-left: 10px;

				&:hover {
					color: var(--tertiary-theme-color, white);
				}

				.fa {
					font-size: 2em;
				}
			}
		}

		h1 {
			font-family: $font-family-teuton;
		}
	}

	& > *:not(header) {
		padding: 0 30px 5px;
	}
}
