section.item-select {
	width: 100%;

	.options-wrapper {
		flex: 1;
		border: 3px solid var(--primary-theme-color);
		display: flex;
		flex-direction: column;
		overflow: auto;

		.options {
			overflow: auto;
			display: flex;
			flex-direction: column;
			flex: 1;

			button {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				margin-bottom: 3px;
				padding: 5px 8px;

				&[active],
				&:hover {
					outline: 3px solid var(--primary-theme-color);
					background: var(--tertiary-theme-color);
				}
			}
		}
	}

	& > .input-wrapper {
		width: 100%;
		margin-bottom: 10px;
	}

	.active-filters-message {
		min-height: 3px;
	}

	& > nav {
		display: flex;
		justify-content: flex-end;
		margin-top: 10px;

		& > * {
			width: unset;

			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
}
