@import "../../../styleConstants";

@mixin storage-unit-row {
	.storage-unit-row {
		--min-frame-width: 200px;
		--gap-size: 5px;

		--frame-background-color: var(--primary-theme-color);
		--frame-color: var(--secondary-theme-color);
		--hover-background-color: var(--tertiary-theme-color, white);
		--hover-color: var(--primary-theme-color);

		--remove-button-height: 50px;

		width: 100%;
		height: 100%;
		background: var(--frame-background-color);
		display: flex;
		flex-wrap: nowrap;
		overflow: hidden;
		font-family: $font-family-teuton;

		.fa-times {
			opacity: 0.5;
		}

		.border-indicator {
			z-index: 1;
			background: var(--primary-theme-color);
			width: 0;
			padding-right: 10px;
			height: 100%;
		}

		&.scroll {
			overflow: auto;
		}
	}
}
