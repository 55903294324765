@import "../../../styles/styleConstants.scss";

section.session-detail {
	h1 {
		text-align: center;
		display: flex;
		flex-direction: column;

		span {
			font-size: 0.5em;
		}
	}

	.session-basics {
		span {
			word-break: break-word;
		}

		.phone,
		.email {
			margin: 5px 0;

			.fa {
				margin-right: 10px;
			}
		}
	}

	.session-state {
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: flex-start;
	}

	.service-details {
		margin: 40px 0;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;

		h1 {
			margin: 5px 15px;
			label {
				position: relative;

				.fa {
					position: absolute;
					right: -1em;
					bottom: 0.8em;
				}
			}
		}
	}

	.session-duration {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		justify-content: center;
		align-items: center;
		margin: 40px;

		& > div {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;

			.time {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			& > div {
				padding: 10px;
				display: grid;
				text-align: center;
				position: relative;

				h2 {
					font-family: $font-family-teuton;
					font-size: 1.5em;
				}
			}

			.start,
			.end {
				background: var(--tertiary-theme-color, white);
				border: 5px solid var(--primary-theme-color);
				width: 100%;
				height: 100%;
				grid-gap: 10px;

				&::before {
					@include mixin-absolute-content;
					right: unset;
					bottom: unset;
					top: 50%;

					height: 10px;
					background: var(--primary-theme-color);
					width: 100vw;
					transform: translateY(-50%);
					z-index: -1;
				}
			}

			.start {
				&::before {
					left: unset;
					right: 0;
					transform: scaleX(-1) translateY(-50%);
				}
			}
		}
	}

	.session-description {
		display: flex;
		position: relative;
		margin: 30px 0;

		&::before {
			@include mixin-absolute-content;
			height: 10px;
			background: var(--primary-theme-color);
			top: 50%;
			transform: translateY(-50%);
			margin: 0 -10vw;
			z-index: -1;
		}

		.text-block {
			margin: auto;
			background: var(--tertiary-theme-color);
			width: 80%;
			max-width: 800px;
			min-height: 50px;
			padding: 10px 20px;
			border: 5px solid var(--primary-theme-color);

			label {
				width: 100%;
				text-align: center;
				font-size: 1.5em;
			}

			pre {
				text-align: center;
				padding: 10px 0;
			}
		}
	}

	.summary {
		margin: auto;
		width: 80%;
		max-width: 800px;
	}
}

@media screen and (max-width: 700px) {
	section.session-detail {

		.session-duration {
			grid-template-columns: 1fr;
		}

		.summary {
			width: 100%;
		}
	}
}

@media screen and (max-width: 560px) {
	section.session-detail {
		.session-duration {
			margin-left: 20px;
			margin-right: 20px;
		}
	}
}
