.statistics-revenue {
	display: flex;
	flex-direction: column;
	width: 800px;
	margin: 0 auto;
	border: 5px solid var(--primary-theme-color);

	.interval {
		display: flex;
		justify-content: space-between;
	}
}

@media screen and (max-width: 800px) {
	.statistics-revenue {
		min-width: unset;
		width: 100%;
	}
}
