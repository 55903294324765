@import "../styleConstants";

div.input-container {
	display: flex;
	justify-content: center;
	align-items: center;

	label {
		user-select: none;
	}
}

.input-wrapper,
input,
textarea {
	appearance: none;
	border: 3px solid var(--input-border-color, var(--primary-theme-color));
	color: var(--primary-theme-color);
	padding: 5px 10px;
	outline: none;

	&:focus {
		font-weight: bold;
	}

	&.disabled {
		filter: contrast(0.5);
		cursor: not-allowed;

		* {
			cursor: not-allowed;
		}
	}

	&[readonly] {
		cursor: default;
	}
}

textarea {
	min-height: 3em;
}

.toggle-button {
	--toggle-button-width: 2em;
	--toggle-button-weight-size: 1em;

	label {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	input {

		position: relative;
		appearance: none;
		width: var(--toggle-button-width);
		height: 2em;
		padding: 0 calc(var(--toggle-button-weight-size) / 2) !important;
		box-sizing: unset !important;

		&::before,
		&::after {
			content: "";
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
		}

		&::before {
			width: var(--toggle-button-width);
			height: 0.5em;
			background: var(--secondary-theme-color);
			filter: brightness(0.5);
		}

		&::after {
			width: var(--toggle-button-weight-size);
			height: var(--toggle-button-weight-size);
			background: #333;
			border: 2px solid black;
			transition: 0.2s all;
			transform: translate(-50%, -50%);
		}

		&:checked::after {
			background: var(--tertiary-theme-color, white);
			border: 2px solid var(--primary-theme-color);
			transform: translate(150%, -50%);
		}
	}
}

.input-container {
	&.icon-toggle {
		button {
			display: flex;
			justify-content: center;
			align-items: center;

			&:read-only {
				cursor: default;
			}
		}
	}
}

// TIME INPUT
.time-inputs-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	input {
		width: 1.5em;
		text-align: center;
	}

	button {
		margin-left: 10px;
		height: 100%;
	}

	.active {
		font-weight: bold;
		background: var(--tertiary-theme-color, white);
		border: 2px solid var(--primary-theme-color);
	}
}

// DROPDOWN
.dropdown {
	position: relative;
	display: flex;
	align-items: center;
	outline: none;
	cursor: pointer;

	.input-wrapper {
		width: 100%;
		min-width: 100px;
	}

	input {
		color: inherit;
		border: none;
		background: transparent !important;
		cursor: pointer;
	}
}

.tippy-popper {
	.options {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 3px;
		font-size: 1.2em;
		max-height: 500px;
		overflow-y: auto;

		button {
			padding: 0.2em 0;

			&.active,
			&:hover {
				outline: 3px solid var(--primary-theme-color);
				background: var(--secondary-theme-color);
			}

			&.active {
				font-weight: bold;
			}
		}
	}
}

.input-wrapper {
	display: inline-flex;
	background: var(--tertiary-theme-color, white);

	.prefix {
		margin-right: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	input {
		flex: 1;
		border: none;
		padding: 0;
		margin: 0;

		&:placeholder-shown {
			& ~ .invalidate-button {
				display: none;
			}
		}
	}

	.suffix {
		margin: 0 0 0 10px;
		display: flex;
		justify-content: center;
		align-items: center;

		&:last-child {
			margin-right: 0;
		}

		& + * {
			margin-left: 10px;
		}
	}

	.invalidate-button {
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
		border: none;
		margin: 0 0 0 10px;

		&:hover {
			color: var(--state-error-color);
		}
	}
}

section.items-picker {
	width: 100%;

	header {
		background: var(--primary-theme-color);
	}

	header label,
	button {
		display: block;
		padding: 5px 10px;
		width: 100%;
		text-align: left;
	}

	header label {
		color: var(--secondary-theme-color);
		width: unset;
		flex: 10;
	}

	header,
	& > div {
		display: flex;
	}

	nav {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: var(--primary-theme-color);
		flex: 1;

		button {
			color: var(--secondary-theme-color);
			width: unset;

			&:hover {
				color: var(--tertiary-theme-color, white);
			}
		}
	}

	.options,
	.active {
		overflow: auto;
		border: 5px solid var(--primary-theme-color);
		flex: 10;
		background: var(--tertiary-theme-color, white);

		button {
			&:hover {
				background: var(--secondary-theme-color);
			}
		}
	}
}

.dropzone {
	width: 100%;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	padding: 0;
	position: relative;

	.icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.1;
		font-size: 5em;
		color: var(--primary-theme-color);
		pointer-events: none;
		z-index: 0;
	}

	.dropzone-hint {
		text-align: center;
		pointer-events: none;
		padding: 10px;

		&.center {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		&:not(.center) {
			margin-bottom: -10px;
		}
	}

	& > div {
		flex: 1;

		& > section {
			padding: 10px;
		}
	}

	.dropzone-trigger {
		outline: none;
	}

	input {
		width: 100%;
		height: 100%;
	}
}

.datepicker {
	min-width: 200px;
	width: 100%;

	.input-wrapper {
		display: flex;

		& > label {
			flex: 1;
			margin: 0;
			display: flex;

			&:last-of-type {
				margin-right: 10px;
			}

			input {
				width: 100%;
				text-align: right;
				margin: -5px 0;
			}
		}
	}
}

.input-wrapper.radio-input {
	background: transparent;
	border: none;
	padding: 0;

	.options {
		display: flex;
		width: 100%;

		& > button {
			flex: 1;
			border: 3px solid var(--primary-theme-color);
			padding: 5px 10px;

			&:not(:last-child) {
				margin-right: -3px;
			}

			&:focus {
				background: var(--tertiary-theme-color);
				filter: brightness(0.8);
			}

			&[active="true"],
			&:hover {
				background: var(--tertiary-theme-color);
				filter: none;
			}
		}
	}
}

@media screen and (max-width: 400px) {
	.datepicker {
		[data-tippy-root] {
			position: fixed !important;
			bottom: 0 !important;
			left: 0 !important;
			right: 0 !important;
			top: unset !important;
			margin: 0 !important;
			padding: 0 !important;
			transform: none !important;
			max-width: unset !important;

			.calendar {
				padding: 20px 10px;

				.fa {
					font-size: 1.3em;
				}
			}
		}

		.backdrop {
			@include mixin-absolute-content;
			position: fixed;
			z-index: -1;
			background: rgba(0,0,0,0.2);
		}
	}
}

input::-ms-reveal {
  display: none;
}

.pin-input-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.input-wrapper {
		width: 100%;
	}

	.input-error-message {
		font-size: 0.8em;
		color: var(--state-error-color);
		font-weight: bold;
		background: var(--primary-scope-color);
		padding: 0.25em 1em;
		width: 100%;

		&[data-soft-hidden="true"] {
			opacity: 0;
			user-select: none;
			pointer-events: none;
		}
	}
}
