@import "src/styles/styleConstants";

section.navigation {
	background: var(--primary-theme-color);
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	height: 100vh;
	display: flex;
	flex-direction: column;

	nav {
		overflow: hidden auto;

		& > a,
		& > button {
			width: 100%;
			display: flex;
			align-items: stretch;
			position: relative;
			padding: 0;

			&[active="true"] {
				color: var(--tertiary-theme-color);
			}

			.icon-wrapper {
				width: 70px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 0;
				transition: 0.3s margin;
				position: relative;

				svg {
					position: relative;
					z-index: 4;
					font-size: 1.5em;
				}

				&::before {
					@include mixin-absolute-content;
					z-index: 4;
					right: 16px;
				}
			}

			.button-content {
				position: relative;
				cursor: pointer;
				font-size: 1em;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex: 1;

				&:not(.has-sub-routes) {
					pointer-events: none;
				}

				label {
					padding: 10px 0;
				}

				.expander {
					padding: 10px 20px;

					&.no-interaction {
						pointer-events: none;
						visibility: hidden;
					}
				}
			}
		}
	}

	& > nav {
		margin-top: 20px;

		&::-webkit-scrollbar {
			width: 2px;
			height: 2px;
		}

		& > a,
		& > button {
			position: relative;
			color: var(--secondary-theme-color);

			&.active,
			&:hover {
				color: var(--tertiary-theme-color, white);
			}

			.button-content {
				margin-left: -30vw;
				z-index: 3;
				transition: 0.3s margin;

				button {
					color: inherit;
					margin-left: 10px;
				}
			}

			.icon-wrapper {
				.fake-expander {
					pointer-events: none;
					position: absolute;
					right: 5px;
					font-size: 1em;
					transition: 0.2s all;
					transform: translate(0, -1px);
					opacity: 0.5;
					color: var(--tertiary-theme-color, white);
				}
			}
		}
	}

	&[extended="true"] {
		& > nav {
			& > * {

				.button-content {
					margin-left: 0;
				}

				.icon-wrapper {
					.fake-expander {
						display: none;
					}
				}
			}
		}
	}

	.curtain {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 70px;
		z-index: 2;
		background: var(--primary-theme-color);
		pointer-events: none;

		&.icon-curtain {
			z-index: 3;
		}
	}

	footer {
		width: 100%;
		margin-top: auto;
		display: flex;
		flex-direction: column;
		color: var(--secondary-theme-color);
		align-items: center;
		justify-content: center;
		padding: 5px 0;

		.language-toggle {
			color: inherit;
			font-size: 2em;
			z-index: 4;
			position: relative;
			width: 100%;

			&:hover {
				color: var(--tertiary-theme-color, white);
			}
		}

		label {
			color: inherit;
			position: relative;
			z-index: 10;
		}
	}

	.subsections {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		z-index: -1;

		& > nav {
			position: absolute;
			right: 10px;
			top: 0;
			height: 100%;
			background: white;
			z-index: 0;
			padding: 10px;
			box-shadow: 0 1px 1px 0 rgba(60, 64, 67, .08), 0 1px 3px 1px rgba(60, 64, 67, .16);
			border-right: 5px solid var(--primary-theme-color);
			transform: translateX(0);
			transition: 0.1s transform;

			.active {
				text-decoration: underline;
				font-weight: bolder;
			}

			.button-content {
				padding-right: 20px;
			}

			& > label {
				width: 100%;
				text-align: center;
				height: 44px;
				margin-top: 10px;
			}

			label {
				white-space: nowrap;
			}

			a,
			button,
			.button {
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.visible {
			transform: translateX(100%);
		}
	}
}

button.open-menu {
	display: none;
	justify-content: center;
	align-items: center;
	background: var(--primary-theme-color);
	color: var(--secondary-theme-color);
}

@media screen and (max-width: 700px) {
	section.navigation {
		& > nav {
			& > a,
			& > button {
				.button-content {
					margin-left: -100vw;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	button.open-menu {
		display: flex;
	}

	section.navigation[visible="false"] {
		display: none;
	}
}
