section.service-reservation-restrictions {
	h2 {
		margin-top: 1rem;
	}

	.restrictions {
		.restriction {
			display: grid;
			grid-template-columns: repeat(3, 1fr) auto;
			grid-gap: 0.5rem 0.5rem;
		}

		.period {
			display: flex;
			align-items: center;

			.time-inputs-wrapper {
				flex: 1;
			}
		}

		.remove-button {
			span {
				display: none;
			}
		}

		.full-span {
			grid-column: span 3;
		}

		.add-button {
			margin: 1rem 0;
		}
	}
}

@media screen and (min-width: 850px) {
	section.service-reservation-restrictions {
		.restrictions {
			.remove-button {
				border: none;
				width: unset;

				&:hover {
					background: none;
				}

				svg {
					margin: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 850px) {
	section.service-reservation-restrictions {
		.restrictions {
			display: flex;
			flex-wrap: wrap;
			gap: 0 1rem;

			.restriction {
				flex: 1;
				grid-template-columns: 1fr;

				padding: 1rem 0;
				margin: 0 auto;

				.remove-button {
					span {
						display: initial;
					}
				}

				.full-span {
					grid-column: unset;
				}
			}
		}
	}
}
