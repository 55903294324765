section.hint {
	background: var(--primary-theme-color);
	color: var(--state-warning-color);
	padding: 0.5em 1em;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;

	& > span {
		flex: 1;
		text-align: center;
		min-width: 300px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		svg {
			margin-right: 1em;
		}
	}
}
