@import "../styleConstants";

section.gallery {
	.thumbnails {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		.thumbnail {
			width: 150px;
			height: 150px;
			border: 5px solid var(--primary-theme-color);
			margin: 5px;
			flex-direction: column;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			.image-wrapper {
				height: 100%;
				width: 100%;
			}

			.error-block {
				position: relative;
				z-index: 1;
			}

			& > * {
				position: absolute;
			}

			& > button {
				padding: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--primary-theme-color);
				font-size: 3em;

				.fa {
					display: none;
				}
			}

			&:hover {
				button {
					background: var(--tertiary-theme-color, white);
				}

				.fa {
					display: block;
				}
			}
		}
	}


	.gallery-modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99999;
		background: var(--primary-theme-color);
		padding: 20px;

		button {
			color: inherit;
		}

		.action-buttons {
			position: fixed;
			top: 10px;
			right: 10px;
			font-size: 2em;
			z-index: 2;

			button {
				color: var(--secondary-theme-color);

				&:hover {
					color: var(--tertiary-theme-color, white);
				}
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			height: 100%;
			width: 100%;
			position: relative;
			color: var(--secondary-theme-color);
			--cube-color: var(--secondary-theme-color);

			.active-index {
				position: absolute;
				top: 0;
				left: 0;
			}

			& > .active-image {
				flex: 1;
				min-height: 60%;
				max-height: 60%;
				position: relative;
				width: 100%;
				display: flex;

				.image-wrapper {
					height: 100%;
					width: 100%;
				}

				nav {
					position: absolute;
					top: 50%;
					left: 0;
					width: 100%;
					transform: translateY(-50%);
					font-size: 2em;
					display: flex;
					justify-content: space-between;
					pointer-events: none;

					button {
						pointer-events: auto;

						&:hover {
							color: var(--tertiary-theme-color);
						}
					}
				}
			}

			.thumbnails {
				button {
					padding: 0;
					border: 5px solid rgba(0,0,0,0.2);
					margin: 5px;

					&[active="true"],
					&:hover {
						border-color: var(--tertiary-theme-color);
						background: transparent !important;
					}
				}

				.image-wrapper {
					width: 20vw;
					height: 20vw;
					max-height: 130px;
					max-width: 130px;
					margin: 0;
					border: none;
				}
			}
		}
	}
}
