section.external-location-detail {
	.external-location {
		padding: 20px 40px;

		section.language-switch,
		section.locale-wrapper {
			margin-top: 40px;

			h3 {
				.fa {
					margin-right: 15px;
				}
				margin-bottom: 10px;
			}
		}
	}
}
