@import "../../styleConstants";

section.location-detail {
	flex: 1;
	display: flex;
	flex-direction: column;

	h3 {
		font-weight: normal;
		font-size: 1.2em;

		.fa {
			margin-right: 15px;
		}
	}

	div.location-detail-data-wrapper {
		overflow: hidden auto;
		position: relative;
		flex: 1;
	}

	.in {
		opacity: 1 !important;
	}

	.units-select {
		border-bottom: none;
		padding: 0 10px;
		pointer-events: none;
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		z-index: 3;
		display: flex;
		flex-direction: row;

		.overflow-menu {
			display: flex;

			.overflow-container {
				display: none;
			}
		}

		nav {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			flex: 1;
		}

		a {
			margin-left: 3px;
			padding: 5px 10px;
			border: 5px solid var(--primary-theme-color);
			border-top: 0;
			border-radius: 0 0 10px 10px;
			text-decoration: none;
			display: flex;
			justify-content: center;
			align-items: center;
			pointer-events: initial;
			background: var(--secondary-theme-color);

			&.active,
			&:focus,
			&:hover {
				background: var(--tertiary-theme-color, white);
			}

			&#more:focus {
				border-radius: 0;
			}

			&.add-unit {
				cursor: pointer;
			}

			button {
				svg {
					margin-left: 10px;

					&:hover {
						color: var(--secondary-theme-color);
					}
				}
			}
		}

		.state-icons {
			display: flex;
			justify-content: center;
			align-content: center;

			& > * {
				margin-left: 10px;
			}
		}
	}

	.tab-wrapper {
		max-height: 100%;
	}

	nav.toolbar {
		button {
			&:hover {
				&.remove-location {
					color: var(--state-error-color);
					filter: unset;
				}
			}
		}
	}

	div.information-wrapper {
		margin: 40px;

		& > div {
			margin-bottom: 10px;
		}

		div.row {
			& > div > div {
				margin: 20px 0;
			}
		}

		.developer-description {
			margin-top: 15px;

			textarea {
				width: 100%;
				margin: 10px 0;
			}
		}

		.opening-hours-wrapper {
			button {
				width: 100%;
				padding: 0;
				position: relative;
				color: var(--primary-theme-color);

				.fa.action-button {
					position: absolute;
					bottom: 5px;
					right: 5px;
				}
			}

			.opening-hours {
				display: flex;
				flex-direction: column;

				h3 {
					align-self: flex-start;
					text-align: center;
					margin-bottom: 10px;
				}

				& > p {
					margin-left: 50px;
				}

				& > div {
					display: flex;
					flex-direction: column;
				}

				.entries {
					grid-template-columns: auto 1fr;
					grid-gap: 0 20px;

					label {
						text-align: right;
						align-self: flex-start;
					}

					.time-entries {
						display: block;
						text-align: left;
					}

					& > div:last-of-type {
						label {
							color: var(--state-error-color);
						}
					}
				}

				.timezone {
					grid-template-columns: 1fr 2em auto auto;
				}
			}
		}
	}
}

/* Tippy "Dropdown" theme modifier */
.unit-select-dropdown {
	.tippy-box {
		color: var(--tertiary-scope-color);
	}

	.tippy-box[data-placement^=bottom]>.tippy-arrow:before {
		--tippy-arrow-background: var(--input-border-color);
		top: -11px;
	}

	border: 5px solid var(--input-border-color);
	border-radius: 5px;

	nav {
		a {
			padding: 5px 10px;
			color: inherit;
			display: flex;
			justify-content: space-between;

			.state-icons {
				span {
					margin-left: 10px;
				}
			}

			&.active {
				text-decoration: underline;
			}
		}
	}
}

@media screen and (max-width: 874px) {
	section.location-detail {
		div.information-wrapper {
			.opening-hours-wrapper {
				.opening-hours {
					align-items: center;

					h3 {
						align-self: center;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 400px) {
	section.location-detail {
		div.information-wrapper {
			margin: 15px;
		}

		&[edit="true"] .toolbar {
			.input-wrapper {
				min-width: 0;

				input {
					min-width: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 300px) {
	section.location-detail {
		div.information-wrapper {
			margin: 15px;
		}

		&[edit="true"] .toolbar {
			flex-direction: column;

			.back-button {
				align-self: flex-start;
			}

			.input-wrapper {
				margin: 10px 0;
				width: 100%;
			}
		}
	}
}
