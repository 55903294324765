@keyframes sk-cubeGridScaleDelay {
	0%, 70%, 100% {
		//transform: scale3D(1, 1, 1);
		transform: scale(1, 1);
	}
	35% {
		//transform: scale3D(0, 0, 1);
		transform: scale(0, 0);
	}
}

.sk-cube-grid {
	width: var(--cube-size);
	height: var(--cube-size);
	position: fixed;
	right: 2%;
	bottom: 2%;
	z-index: 9999;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 2px;
	pointer-events: none;

	&[block="true"] {
		position: static;
		margin: auto;
		z-index: auto;
	}

	.sk-cube {
		background-color: var(--cube-color, var(--primary-theme-color));
		animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
		backface-visibility: hidden;
		transform: translateZ(0);
		transform-style: preserve-3d;
	}

	.sk-cube1,
	.sk-cube5,
	.sk-cube9 {
		animation-delay: 0.2s;
	}

	.sk-cube2,
	.sk-cube6 {
		animation-delay: 0.3s;
	}

	.sk-cube3 {
		animation-delay: 0.4s;
	}

	.sk-cube4,
	.sk-cube8 {
		animation-delay: 0.1s;
	}

	.sk-cube7 {
		animation-delay: 0s;
	}
}
