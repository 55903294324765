section.voucher-detail {
	.service-restrictions {
		.content {
			margin: auto;

			& > div {
				display: flex;
			}
		}
	}
}
