section.tariff-detail {
	.content-wrapper {
		.reservation-costs,
		.brackets {
			display: flex;
			justify-content: center;

			.grid-table {
				grid-template-columns: repeat(4, auto);
				grid-gap: 10px 20px;
				margin: auto;

				.toggle-button label span {
					display: none;
				}
			}
		}

		.reservation-costs {
			.grid-table {
				grid-template-columns: repeat(5, auto);
			}
		}
	}
}

@media screen and (max-width: 850px) {
	section.tariff-detail {
		.content-wrapper {
			.reservation-costs,
			.brackets {
				.grid-table.edit {
					grid-template-columns: 1fr;
					grid-gap: 0;

					& > label {
						display: none;
					}

					.toggle-button label span {
						display: initial;
					}

					span,
					.id {
						text-align: center;
					}

					.id {
						margin-top: 15px;
					}

					.input-wrapper {
						margin: 5px 0;
					}

					.input-container {
						margin: auto;
					}

					.remove-button {
						margin: 5px 0 10px;
						width: 100%;
					}
				}
			}
		}
	}
}
