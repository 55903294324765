.cube {
	width: 1em;
	height: 1em;
	position: relative;
	font-size: 2em;
	appearance: none;
	outline: none;
	border: none;
	background: none;
	margin: 0.7em !important;

	&:disabled {
		filter: brightness(0.5) opacity(0.5);
		cursor: not-allowed;
	}

	&::before,
	&::after {
		content: "";
		width: 1em;
		height: 1em;
		border: 5px solid var(--primary-theme-color);
		border-radius: 10px;
		box-sizing: unset;
		position: absolute;
		top: 0;
		left: -0.15em;
	}

	&:checked::before {
		left: unset;
		right: -0.5em;
		top: -0.5em;
		background: none;
	}

	&:checked::after {
		background: var(--tertiary-theme-color, white);
	}

	&::before {
		left: unset;
		right: -0.5em;
		top: -0.5em;
		background: var(--tertiary-theme-color, white);
	}
}
