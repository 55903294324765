@import "../styleConstants.scss";

section.coords-input {
	display: flex;
	flex-direction: row !important;
	flex-wrap: wrap;

	.map-frame {
		flex: 1 1 500px;
		position: relative;
		display: flex;
		border: 3px solid var(--primary-theme-color);

		.map {
			flex: 1;
			width: 100%;
			height: 100%;
			min-height: 60vh;
		}

		.sk-cube-grid {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%);
			margin: 0;
		}
	}

	aside {
		flex: 1 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0 15px;
	}
}
