$theme-colors: (
		"primary": #212121,
		"secondary": #f8db14
);

$primary-text-color: rgba(0, 0, 0, .81);
$underlying-text-color: #fff;
$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-teuton: "TeutonMager", 'Roboto', sans-serif;

@mixin mixin-absolute-content {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

@mixin grid-icon-auto-1fr-wrap {
	&.responsive,
	&:not(.static) {
		grid-template-columns: auto 1fr;

		& > *:nth-child(3n) {
			grid-column: span 2;
		}

		label {
			text-align: left;
			justify-content: flex-start;
		}

		.full-span {
			grid-column: span 2;
		}
	}
}

@mixin separator-compact {
	grid-template-areas: "title title title" "left . right";
	grid-gap: 0;
	align-items: flex-start;

	&.title {
		margin: 0 0 10px 0;

		& > label {
			margin: 0;
			border: none;
			border-bottom: 5px solid var(--primary-theme-color);

			&::before {
				display: none;
			}
		}
	}

	& > label {
		min-width: 250px;
	}

	& > div {
		margin-top: -5px;
	}

	button {
		padding: 0.2em 0.5em;
		min-width: 50px;

		&:only-child {
			border-radius: 0 0 10px 10px;
		}
	}
}
