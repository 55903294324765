@mixin storage-service {
	.storage-service {
		display: flex;
		width: 100%;
		height: 100%;
		flex: 1;
		background: var(--frame-color);
		color: var(--frame-background-color);
		border: none;
		outline: none;
		padding: 0;
		cursor: default;
		position: relative;
		justify-content: center;
		align-items: center;

		&:not([disabled]):hover {
			background: var(--hover-background-color);
		}

		.state-icons {
			position: absolute;
			bottom: 0.5em;
			right: 0.5em;
			color: var(--primary-theme-color);
			font-size: 0.6em;
			display: flex;
			opacity: 0.7;

			.fa {
				margin-left: 0.5em;
			}
		}

		div {
			padding: 10px;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&.has-icon {
			div {
				flex-direction: column;

				.fa {
					font-size: 1.3em;
					margin-bottom: -15px;
				}

				span {
					font-size: 0.5em;
				}
			}
		}

		&[enabled="false"] {
			--disabled-background: var(--secondary-theme-color);

			&:hover {
				--disabled-background: var(--hover-background-color);

				background: var(--disabled-background);
				color: var(--hover-color);
				font-weight: bold;

				filter: brightness(0.4);
			}

			div {
				background: repeating-linear-gradient(
					45deg,
					transparent,
					transparent 10px,
					var(--disabled-background) 10px,
					var(--disabled-background) 20px
				);
				filter: brightness(0.9);
				font-weight: bold;
				opacity: 0.5;
			}
		}

		&[empty="true"] {
			opacity: 0.5;
		}

		&[disabled][empty="true"] {
			* {
				cursor: not-allowed;
			}
		}
	}
}
