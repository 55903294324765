@import url("https://fonts.googleapis.com/css?family=Roboto");
@import "styleConstants";

:root {
	--primary-theme-color: #212121;
	--secondary-theme-color: #f8db14;
	--tertiary-theme-color: white;

	--state-info-color: #3498db;
	--state-success-color: #4caf50;
	--state-warning-color: #f9a825;
	--state-error-color: #e74c3c;

	--primary-scope-color: var(--primary-theme-color);
	--secondary-scope-color: var(--secondary-theme-color);
	--tertiary-scope-color: var(--tertiary-theme-color);

	--cube-size: 60px;
}

@media screen and (min-width: 500px) {
	/* Scrollbar */
	::-webkit-scrollbar {
		width: 15px;
		height: 15px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.2);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: var(--secondary-theme-color);
		border: 10px solid rgba(0, 0, 0, 0.5);
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: var(--tertiary-theme-color, white);
		border-color: transparent;
	}

	::selection {
		color: var(--secondary-theme-color);
		background: var(--primary-theme-color);
	}
}

* {
	scrollbar-color: #7b6d0a rgba(0, 0, 0, 0.2); /*Firefox*/

	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}
}

html {
	font-family: sans-serif;
	line-height: 1.15;
}

input, button, select, optgroup, textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

textarea {
	resize: vertical;
}

label {
	display: inline-block;
}

a {
	color: var(--primary-theme-color);
	text-decoration: none;

	&:not([disabled]):hover {
		text-decoration: underline;
	}
}

body {
	margin: 0;
    font-family: "Roboto",sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
	background-color: var(--secondary-theme-color);
	overflow-x: hidden;
	position: relative;
	height: 100vh;

	* {
		letter-spacing: 1px;
		text-rendering: optimizeLegibility;
	}

	main {
		height: 100%;
		display: flex;

		& > section:not(.navigation) {
			height: 100%;
			width: calc(100% - 70px);
			margin-left: 70px;
			display: flex;
			flex-direction: column;

			& > div:not(.title):not(.sk-cube-grid) {
				flex: 1;
				overflow: hidden auto;
			}

			& > .content-wrapper {
				margin: 0;
			}

			div.content-wrapper {
				padding: 25px;
			}
		}
	}
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

.fa {
	fill: currentColor;
}

.fadeIn {
	opacity: 0;
	animation: fadeIn 0.5s forwards;
}

.invert-logo {
	filter: invert(1) grayscale(1);
}

.smooth-scroll {
	scroll-behavior: smooth;
}

input[type="checkbox"] {
	cursor: pointer;
	border: none;
	margin-right: auto;
}

h1 {
	font-size: 2.5rem;
	font-weight: 500;
	line-height: 1.2;
}

h3 {
	font-size: 1.75rem;
}

button {
	outline: none;
	background: none;
	border: none;
	user-select: none;
	cursor: pointer;
	color: inherit;

	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&[active="true"] {
		background: var(--tertiary-theme-color) !important;
	}

	&:focus {
		outline: 0;
	}

	* {
		pointer-events: none;
	}

	a {
		pointer-events: all;
	}
}

a {
	cursor: pointer;

	&:not(.user-selectable) {
		user-select: none;
	}

	* {
		cursor: pointer;
	}
}

button,
a,
.button {
	&.fancy {
		width: 100%;
		padding: 10px;
		font-family: $font-family-teuton;
		border: 3px solid var(--primary-theme-color);
		font-weight: bold;

		.fa:not(:only-child) {
			margin-right: 10px;
		}

		&:not([disabled]):hover {
			background: var(--tertiary-theme-color, white);
		}

		&.invert {
			&:not([disabled]):hover {
				color: var(--secondary-theme-color);
				background: var(--primary-theme-color);
			}
		}
	}

	&.no-style {
		padding: 0;
		appearance: none;
	}

	&[disabled] {
		&,
		* {
			cursor: not-allowed;
		}
	}
}

.button-wrapper {
	display: flex;

	button,
	a,
	.button {
		flex: 1;
	}
}

.label,
label {
	font-weight: bold;
	font-family: $font-family-teuton;
}

pre {
	margin: 0;
	font-family: inherit;
	font-size: 1em;
	color: inherit;
	line-height: 1.2;
	overflow: visible;
	white-space: pre-wrap;
	text-align: justify;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	display: none;
}

.bg-primary {
	background: var(--primary-theme-color);
}

.bg-secondary {
	background: var(--secondary-theme-color);
}

.bg-tertiary {
	background: var(--tertiary-theme-color, white);
}

.cl-primary {
	color: var(--primary-theme-color);
}

.cl-secondary {
	color: var(--secondary-theme-color);
}

.cl-tertiary {
	color: var(--tertiary-theme-color, white);
}

.cl-info {
	color: var(--state-info-color);
}

.cl-success {
	color: var(--state-success-color);
}

.cl-warning {
	color: var(--state-warning-color);
}

.cl-error {
	color: var(--state-error-color);
}

.dark {
	--primary-scope-color: var(--secondary-theme-color);
	--secondary-scope-color: var(--primary-theme-color);
	--tertiary-scope-color: #ccc;

	--input-border-color: #ccc;

	--dropdown-content-background: var(--secondary-theme-color);
	--dropdown-content-color: var(--primary-theme-color);
}

.grid-table {
	--grid-column-gap: 10px;

	display: grid;
	grid-gap: 5px var(--grid-column-gap);
	align-items: center;

	&.stripes {
		align-items: stretch;
	}

	& > * {
		display: flex;
		align-items: center;
	}

	.label,
	label {
		font-weight: 700;
		justify-content: flex-end;
		text-align: right;

		&::after {
			content: ":";
		}
	}

	&.no-label-content {
		.label,
		label {
			&::after {
				content: ""
			}
		}
	}

	.with-suffix {
		margin-right: calc(-1 * var(--grid-column-gap));
		justify-content: flex-end;
	}

	/* Must be placed as many times as .full-width is spanning columns */
	& > .placeholder {
		display: none;
	}
}

.grid-icon-auto-1fr {
	grid-template-columns: 2em auto 1fr;

	&.stripes {
		& > *:nth-child(6n) {
			position: relative;
			background: rgba(0, 0, 0, 0.2);
		}

		& > *:nth-child(6n - 1) {
			position: relative;
			background: rgba(0, 0, 0, 0.2);

			&::before {
				@include mixin-absolute-content;
				left: unset;
				right: calc(-1 * var(--grid-column-gap));
				width: var(--grid-column-gap);
				background: rgba(0, 0, 0, 0.2);
			}
		}

		& > *:nth-child(6n - 2) {
			background: rgba(0, 0, 0, 0.2);
			position: relative;
			width: 100%;
			height: 100%;

			&::before {
				@include mixin-absolute-content;
				left: unset;
				right: calc(-1 * var(--grid-column-gap));
				width: var(--grid-column-gap);
				background: rgba(0, 0, 0, 0.2);
			}
		}
	}

	&.wrap {
		@include grid-icon-auto-1fr-wrap;
	}

	.full-span {
		grid-column: span 3;
	}
}

.grid-auto-1fr {
	grid-template-columns: auto 1fr;

	&.stripes {
		& > *:nth-child(4n) {
			position: relative;
			background: rgba(0, 0, 0, 0.2);
		}

		& > *:nth-child(4n - 1) {
			position: relative;
			background: rgba(0, 0, 0, 0.2);

			&::before {
				@include mixin-absolute-content;
				left: unset;
				right: calc(-1 * var(--grid-column-gap));
				width: var(--grid-column-gap);
				background: rgba(0, 0, 0, 0.2);
			}
		}
	}

	.full-span {
		grid-column: span 2;
	}
}

.image-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
}

.separator {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-gap: 20px;
	margin: 20px 0;
	grid-template-areas: "left title right";

	a,
	button,
	.separator-action {
		background: var(--secondary-theme-color);
		border: 5px solid var(--primary-theme-color);

		&:hover {
			background: var(--tertiary-theme-color);
		}
	}

	& > label {
		min-width: 350px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2em;
		padding: 0.3em;
		border: 5px solid var(--primary-theme-color);
		grid-area: title;
		background: var(--tertiary-theme-color);
		position: relative;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			height: 10px;
			top: 50%;
			transform: translateY(-50%) scaleX(200);
			background: var(--primary-theme-color);
			z-index: -1;
		}

		.fa {
			margin-right: 15px;
		}
	}

	& > div {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		&:first-of-type {
			grid-area: left;
		}

		&:last-of-type {
			grid-area: right;
		}
	}

	a,
	button {
		padding: 0.5em 1em;
		margin: 0 10px;

		label {
			margin-left: 10px;
		}
	}

	&.compact {
		@include separator-compact;
	}

	.input-wrapper {
		border-width: 5px;
	}
}

h4 {
	margin: 40px 0 15px;
	font-family: $font-family-teuton;
	font-weight: bold;
	display: flex;
	align-items: center;

	.fa {
		margin-right: 15px;
	}
}

.action-buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.flex-row {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;

	& > div {
		flex: 1;

		&:not(:last-of-type) {
			margin-right: 20px;
		}
	}
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.align-left {
	text-align: left !important;
}

.align-center {
	text-align: center !important;
}

.align-right {
	text-align: right !important;
}

.justify-start {
	justify-content: flex-start !important;
}

.justify-end {
	justify-content: flex-end !important;
}

.absolute-overlap {
	@include mixin-absolute-content;
}

.user-selectable {
	cursor: default !important;
}

button.no-style {
	.input-wrapper {
		width: 100%;
	}
}

.toolbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--tertiary-theme-color, white);
	padding: 5px 10px;
	border-bottom: 5px solid var(--primary-theme-color);

	.back-button {
		font-size: 1.5em;
		padding: 0 0.5em;
		outline: none;

		&:hover {
			color: var(--secondary-theme-color);
			filter: brightness(0.4);
		}
	}

	h1, .input-wrapper {
		margin: 0 auto 0 20px;
		font-size: 1.49em;
		font-weight: bold;
		text-shadow: none;
	}

	.input-wrapper {
		height: 100%;
		padding: 0 0.5em;
	}

	.action-buttons {
		display: flex;
		align-items: center;

		button {
			&:hover {
				color: var(--secondary-theme-color);
				filter: brightness(0.4);

				&.remove {
					color: var(--state-error-color);
					filter: unset;
				}
			}
		}

		.fa {
			margin-right: 10px;
			font-size: 1.2em;
		}
	}
}

.box-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 30px 5%;
	text-align: center;
	justify-content: space-around;
	margin-bottom: 40px;

	& > * {
		background: var(--tertiary-theme-color, white);
		border: 5px solid var(--primary-theme-color);
		padding: 5px 20px;
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: center;

		&::before {
			content: "";
			position: absolute;
			width: 500vw;
			height: 10px;
			top: 50%;
			transform: translate(-50%, -50%);
			background: var(--primary-theme-color);
			z-index: -1;
		}

		label {
			font-size: 2em;
			margin-bottom: 5px;
		}

		& > .fa {
			display: none;
		}
	}
}

.error-block {
	margin-bottom: 0;
	padding: 5px;
	height: 100%;
	width: 100%;
	color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
	display: flex;
	align-items: center;
	justify-content: center;
}

p, h1, h2, h3, h5, h6, label {
	margin: 0;
}

.remove-button {
	&:hover {
		color: var(--state-error-color) !important;
		filter: unset !important;
		opacity: 1 !important;
	}
}

.item-redirect {
	color: inherit;

	&:hover {
		color: var(--state-info-color);
	}
}

.grid-button {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: 20px;
	text-align: center;
	justify-content: center;

	svg {
		margin: auto;
	}
}

.bordered {
	border: 5px solid var(--primary-theme-color);
}

.double-contrast {
	filter: contrast(2);
}

.loading-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--secondary-scope-color);
	display: flex;
	align-items: center;
	justify-content: center;
}

/* ==== Tippy styles ==== */

[data-tippy-root] {
	[data-placement^=top] .tippy-arrow::before {
		border-top-color: var(--tippy-arrow-background, var(--tippy-background)) !important;
	}

	[data-placement^=bottom] .tippy-arrow::before {
		border-bottom-color: var(--tippy-arrow-background, var(--tippy-background)) !important;
	}

	[data-placement^=left] .tippy-arrow::before {
		border-left-color: var(--tippy-arrow-background, var(--tippy-background)) !important;
	}

	[data-placement^=right] .tippy-arrow::before {
		border-right-color: var(--tippy-arrow-background, var(--tippy-background)) !important;
	}

	.tippy-arrow {
		z-index: 2;
		pointer-events: none !important;
	}

	.tippy-content {
		background: var(--tippy-background);
		padding: .3125rem .5625rem;
		border-radius: 10px;
		z-index: 2;
		box-shadow: 0 1px 1px 0 rgba(60, 64, 67, .08), 0 1px 3px 1px rgba(60, 64, 67, .16);
	}
}

[data-theme~="cubesave"] {
	background: none;
	padding: 0;

	--tippy-background: var(--primary-theme-color);
	color: var(--tertiary-theme-color, white);

	.tippy-arrow {
		--tippy-arrow-background: var(--secondary-theme-color);
	}

	.tippy-content {
		border: 3px solid var(--secondary-theme-color);
	}

	button {
		color: var(--tertiary-theme-color);
	}
}

[data-theme~="remove-confirm"] {
	background: none;
	padding: 0;

	--tippy-background: var(--primary-theme-color);

	.tippy-arrow {
		--tippy-arrow-background: var(--state-error-color);
	}

	.tippy-content {
		border: 3px solid var(--state-error-color);
		font-family: $font-family-teuton;
		font-size: 1.15em;
		font-weight: bold;
		padding: 0;
		overflow: hidden;
	}

	button {
		padding: 0.5em;
		color: var(--state-error-color);
		font-weight: bold;

		&:hover {
			background: var(--state-error-color);
			color: var(--primary-theme-color);
		}
	}
}

.dropdown-content {
	position: fixed;
	background: var(--dropdown-content-background, var(--primary-theme-color));
	margin: -3px 0; //Must be the same as the border-width
	border: 3px solid var(--input-border-color, var(--primary-theme-color));
	z-index: 999;
	display: flex;
	color: var(--dropdown-content-color, var(--tertiary-theme-color));
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	//--test: #4e4e4e;
	//box-shadow:  -1px 1px var(--test), -2px 2px var(--test), -3px 3px var(--test), -4px 4px var(--test), -5px 5px var(--test);

	.options {
		overflow: auto;
		padding: 0;
		flex: 1;
		position: relative;

		button {
			display: block;
			width: 100%;
			text-align: left;
			border-bottom: 3px solid var(--dropdown-content-background, var(--primary-theme-color));
			padding: 5px 8px;
			filter: brightness(0.8);

			&:first-of-type {
				border-top: none;
			}

			&:last-of-type {
				border-bottom: none;
			}

			&:focus {
				background: var(--tertiary-theme-color);
				filter: brightness(0.8);
				color: var(--primary-theme-color);
				border-color: var(--input-border-color, var(--primary-theme-color));
			}

			&:hover,
			&[active="true"] {
				background: var(--tertiary-theme-color) !important;
				color: var(--primary-theme-color);
				font-weight: bold;
				filter: none;
				border-color: var(--input-border-color, var(--primary-theme-color));
			}
		}
	}
}

[data-theme~="language"] {
	background: var(--primary-theme-color);
	font-family: $font-family-teuton;
}

/* ==== Responsivity media ==== */
@media screen and (max-width: 800px) {
	.separator {
		@include separator-compact;
	}
}

@media screen and (max-width: 560px) {
	.box-grid.simplify {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		grid-gap: 0;

		& > div {
			flex: 50%;
			background: transparent;
			border: none;
			flex-direction: row;
			justify-content: unset;
			flex-wrap: wrap;
			margin-bottom: 20px;
			padding: 0 20px;
			min-width: 100px;
			text-align: left;

			&::before {
				content: none;
			}

			& > .fa {
				display: block;
			}

			label {
				font-size: 1em;
				margin: 0 0 0 10px;

				&:after {
					content: ":"
				}
			}

			span {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	body {
		font-size: 0.8em;

		* {
			letter-spacing: 0.3px;
		}

		main {
			& > section:not(.navigation) {
				width: calc(100% - 20px);
				margin-left: 0;

				div.content-wrapper {
					padding: 10px;
				}
			}
		}

		.grid-icon-auto-1fr {
			@include grid-icon-auto-1fr-wrap;
		}
	}

	h1 {
		font-size: 1.7em;
	}

	.list-wrapper {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
	}

	.flex-row {
		display: block;

		& > div {
			flex: 1;
		}
	}
}
