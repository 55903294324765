section.new-reservation-modal {
	display: flex;
	flex-direction: row !important;
	width: 100%;

	section.reservation-calendar-wrapper {
		flex: 1;
	}

	.reservation-details {
		margin: 10px;
		display: flex;
		flex-direction: column;

		label {
			display: block;
		}

		.action-buttons {
			margin-top: auto;

			.submit {
				margin-top: 10px;
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	section.new-reservation-modal {
		flex-direction: column !important;
	}
}
