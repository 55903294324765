@mixin storage-frame {
	.storage-frame {
		flex: 1;
		height: 100%;
		display: flex;
		margin-right: var(--gap-size);
		border: 10px solid var(--primary-theme-color);
		border-left: 0;
		border-right: 0;
		min-width: var(--min-frame-width);
		position: relative;

		&:last-of-type {
			margin: 0;
		}

		.state-icons {
			position: absolute;
			bottom: 0;
			right: 10px;
			color: var(--frame-background-color);
		}

		&[data-frame-type="FOUR_RECTANGLES"] {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			grid-gap: var(--gap-size);
			font-size: 0.8em;
		}

		&[data-frame-type="TERMINAL"] {
			color: var(--frame-background-color);
			background: var(--frame-color);
			font-size: 2em;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&[data-frame-type="ADD"],
		&[data-frame-type="NONE"] {
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--frame-color);

			&:hover {
				background: var(--hover-background-color);
				color: var(--hover-color);
			}
		}
	}
}
