@import "../../styleConstants.scss";

section.new-delivery {
	display: grid;
	grid-template-rows: auto 1fr;
	flex: 1;
	height: 600px;
	max-height: 100%;
	overflow: hidden;

	.type-selection {
		display: flex;
		justify-content: flex-end;
		border: 3px solid var(--primary-theme-color);
		margin-bottom: 1rem;

		button {
			flex: 1;
			padding: 5px 10px;
			outline: 3px solid var(--primary-theme-color);
			background: var(--primary-scope-color);
			color: var(--secondary-scope-color);
			white-space: pre-line;

			&:not(:last-of-type) {
				margin-right: 3px;
			}

			&[active],
			&:hover {
				background: var(--tertiary-theme-color, white);
				color: var(--primary-scope-color, black);
			}
		}
	}

	.delivery-details {
		flex: 1;
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;
		overflow: auto;

		nav {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			flex: 0;
			position: relative;
			margin: 0 20px auto 0;

			&::before {
				@include mixin-absolute-content;
				left: 50%;
				right: 50%;
				background: var(--primary-theme-color);
				padding: 5px;
				transform: translateX(-50%);
			}

			button {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: relative;
				z-index: 1;
				background: var(--secondary-theme-color);
				margin: 0;

				&:not(:last-of-type) {
					margin-bottom: 10px;
				}

				& > .fa {
					margin: 0 0 10px;
				}

				.error-badge {
					position: absolute;
					right: 0;
					top: 0;
					font-size: 1.2em;
					transform: translate(50%, -50%);
					background: var(--primary-theme-color);
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					padding: 2px;
				}
			}
		}

		.form-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;

			.delivery-form {
				flex: 1;
				overflow: auto;

				h4 {
					width: 100%;
					justify-content: center;
					margin: 30px 0;
				}
			}
		}

		.next {
			margin: 10px 0 0 auto;
			width: unset;
			padding-left: 20px;
		}
	}

	textarea {
		resize: vertical;
		margin-bottom: 40px;
	}

	.spinner-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: var(--secondary-scope-color);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
	}
}

@media screen and (max-width: 650px) {
	section.new-delivery {
		overflow: visible;

		.delivery-details {
			flex-direction: column;
			overflow: visible;

			nav {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(6em, 1fr));
				grid-gap: 10px;
				margin: 0 0 30px;
				align-items: stretch;

				&::before {
					display: none;
				}

				button {
					margin: 0 !important;

					.error-badge {
						transform: translate(-2px, 2px);
					}
				}
			}

			button.fancy {
				margin-bottom: 1rem;
			}
		}
	}
}
