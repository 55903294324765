.list-wrapper {
	display: flex;
	flex-wrap: wrap;

	.list-item {
		border: 5px solid var(--primary-theme-color);
		margin: 10px;
		min-width: 250px;
		min-height: 150px;
		padding: 10px 20px;
		text-decoration: none;
		background: var(--primary-theme-color);
		display: flex;
		justify-content: center;
		align-items: center;
		color: var(--secondary-theme-color);
		position: relative;
		flex: 1;

		&:hover {
			background: var(--tertiary-theme-color, white);
			color: var(--primary-theme-color);
			text-decoration: none;

			.front {
				display: none;
			}

			.back {
				display: flex;
			}
		}

		& > small {
			position: absolute;
			right: 1em;
			top: 1em;
			color: var(--tertiary-theme-color, white);
		}

		.back {
			width: 100%;
			height: 100%;
			flex-direction: column;
			margin-left: 10px;
			display: none;

			nav {
				margin-top: auto;
				align-self: flex-end;
				text-align: right;
			}

			label {
				font-size: 1.2em;
			}
		}

		.front {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}

	@for $i from 0 through 100 {
	    .list-item:nth-of-type(#{$i + 1}n) {
	        animation-delay: #{$i * 0.03}s !important;
	    }
	}
}
