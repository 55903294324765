@import "../styleConstants";

div.listing-page {

	.bordered,
	&.bordered {
		position: relative;
		border: 5px solid var(--primary-theme-color);
	}

	.footer-static,
	&.footer-static {
		footer {
			position: static;
		}
	}
}
