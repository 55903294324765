@import "../../styleConstants";

section.delivery-page {
	height: 100%;
	display: flex;
	flex-direction: column;

	header {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		font-size: 0.9em;
		position: sticky;
		top: 0;

		.image-wrapper {
			margin: 0 30px;
		}

		form {
			display: flex;
			align-items: stretch;
			flex: 1;
			justify-content: flex-end;

			label {
				align-self: center;
			}

			.input-wrapper {
				height: unset;
				margin: 0 10px;

				input {
					width: 75px;
				}
			}

			button {
				width: unset;

				&.fancy:hover {
					background: var(--primary-theme-color);
					color: var(--secondary-theme-color);
				}
			}
		}
	}

	.error-block {
		flex: 1;

		& > div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-self: center;

			svg {
				align-self: center;
				margin-bottom: 10px;
				font-size: 5em !important;
			}

			span {
				font-size: 2em;
				text-align: center;
			}
		}
	}

	.session-detail {
		flex: 1;

		.action-buttons {
			display: flex;
			justify-content: flex-end;
			margin: 15px;

			button {
				width: unset;
				display: flex;
				align-items: center;

				.fa {
					width: 1.5em;
					height: 1em;
				}

				&:not(:first-of-type) {
					margin-left: 10px;
				}
			}
		}

		& > .address {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			h2 {
				font-family: $font-family-teuton;
			}

			.pickup,
			.delivery {
				min-width: 400px;
				flex: 1;
				border: 3px solid var(--primary-theme-color);
				margin: 15px;
				padding: 10px;
				background: var(--tertiary-theme-color);
			}
		}

		.notes-wrapper {
			display: flex;
			justify-content: center;
			position: relative;

			hr {
				position: absolute;
				top: 50%;
				width: 100%;
				transform: translateY(-50%) scaleX(2000);
				background: var(--primary-theme-color);
				height: 10px;
				border: none;
			}

			.notes {
				border: 3px solid var(--primary-theme-color);
				margin: 15px;
				padding: 10px;
				background: var(--tertiary-theme-color);
				width: 500px;
				max-width: 100%;
				position: relative;
			}
		}
	}

	.completed {
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.fa {
			font-size: 4em;
			margin-bottom: 15px;
		}
	}

	.language-toggle {
		position: absolute;
		left: 10px;
		bottom: 40px;
		font-size: 1.8em;
		z-index: 9;
	}

	footer {
		background: var(--primary-theme-color);
		padding: 10px;
		width: 100%;
		color: var(--secondary-theme-color);
		font-family: $font-family-teuton;
		font-weight: bold;
		position: sticky;
		bottom: 0;

		&,
		& > div {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		flex-direction: column;

		label {
			color: var(--tertiary-theme-color);
		}

		a {
			color: inherit;
		}

		span {
			display: block;
			margin: 0 20px;
			color: var(--tertiary-theme-color);
		}
	}
}

@media screen and (max-width: 860px) {
	section.delivery-page {
		.session-detail {
			& > .address {
				flex-direction: column;

				.pickup,
				.delivery {
					min-width: unset;
				}
			}
		}
	}
}

@media screen and (max-width: 660px) {
	section.delivery-page {
		header {
			flex-direction: column;

			.image-wrapper {
				margin: 0 0 10px;
				max-width: 329px;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}
}

@media screen and (max-width: 310px) {
	section.delivery-page {
		.session-detail {
			.action-buttons {
				flex-direction: column;

				button {
					width: 100%;
					margin: 10px 0 !important;
				}
			}
		}

		.language-toggle {
			position: static;
			color: var(--secondary-theme-color);
		}

		footer {
			div {
				flex-direction: column;

				span {
					display: none;
				}
			}
		}
	}
}
