@import "../styleConstants";

section.login-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	img {
		margin-bottom: 3em;
	}

	.forgotten-password {
		margin-left: auto;

		&:hover {
			text-decoration: underline;
		}
	}

	.language-toggle {
		position: fixed;
		bottom: 0.5em;
		left: 0.5em;
		font-size: 2em;
	}

	button.fancy {
		margin-top: 15px;
	}
}
